<template>
  <div class="h-100 d-flex flex-column px-5 py-2">
    <a-modal
      v-model:visible="showVideoModal"
      centered
      :title="videoToPreview?.name || 'Title'"
      :footer="null"
      destroy-on-close
      z-index="10000000001"
    >
      <template #closeIcon>
        <close-outlined id="rd-preview-modal-close-btn" />
      </template>
      <video class="w-100" controls :src="videoToPreview?.fileURL" />
    </a-modal>

    <div style="flex-grow: 1;">
      <a-upload-dragger
        name="file"
        multiple
        :file-list="[]"
        :before-upload="(f) => false"
        :class="{ 'disable-click': isUploading }"
        @change="handleAddFile"
      >
        <p class="ant-upload-drag-icon">
          <inbox-outlined />
        </p>

        <p class="ant-upload-text">
          Click or drag file(s) to this area to upload
        </p>
        <a-typography-text type="secondary">
          Accepted Files: {{ JSON.stringify(acceptedExtensions) }}
        </a-typography-text>
      </a-upload-dragger>
    </div>

    <div class="d-flex flex-column mx-auto w-100 mt-2" style="flex-grow: 5;">
      <a-list
        item-layout="horizontal"
        :data-source="fileList"
        class=" bg-light"
        size="small"
        style="flex-grow: 1; height: 1px; overflow-y: auto;"
        row-key="uuid"
      >
        <template #renderItem="{ item, index }">
          <a-list-item
            class="list-item"
            :class="{
              uploading: index === fileBeingUploaded,
            }"
          >
            <a-list-item-meta :title="item.name">
              <template #description>
                <a-progress :percent="item.progress" />
                <a-typography-text type="secondary">
                  {{ `${item.uploadedPercent} of ${getFileSize(item.size)}` }}
                </a-typography-text>
              </template>
              <template #avatar>
                <video
                  :src="item.fileURL"
                  width="70"
                  height="70"
                  class="mt-1"
                />
              </template>
            </a-list-item-meta>

            <template #actions>
              <a-tooltip title="Preview file">
                <eye-outlined
                  class="text-primary clickable"
                  :class="{ 'disable-click': isUploading }"
                  @click="handlePreview(item)"
                />
              </a-tooltip>
              <a-tooltip title="Remove file">
                <delete-outlined
                  :class="{ 'disable-click': isUploading }"
                  class="text-danger clickable"
                  @click="handleRemove(item)"
                />
              </a-tooltip>
            </template>
          </a-list-item>
        </template>
      </a-list>

      <a-button
        type="primary"
        class="mx-auto my-3"
        :loading="isUploading"
        :disabled="isDisabled"
        @click="uploadFiles"
      >
        {{ isUploading ? 'Uploading' : 'Upload' }}
      </a-button>
    </div>
  </div>
</template>
<script>
import {
  EyeOutlined,
  DeleteOutlined,
  InboxOutlined,
} from '@ant-design/icons-vue';
import { uuid } from 'vue-uuid';
import UploadService from 'src/services/upload';
export default {
  components: {
    InboxOutlined,
    EyeOutlined,
    DeleteOutlined,
  },
  inject: ['toast'],
  props: {
    acceptedExtensions: {
      type: Array,
      required: true,
      default: () => ['.mp4', '.mkv'],
    },
    payload: {
      type: Object,
      required: true,
      default: () => ({
        task_id: 1696,
        Validation: false,
      }),
    },
    uploader: {
      type: Function,
      required: true,
      default: UploadService.uploadVideo,
    },
  },
  emits: ['upload', 'uploaded'],
  data() {
    return {
      fileList: [],
      isUploading: false,
      showVideoModal: false,
      videoToPreview: null,
      fileBeingUploaded: -1,
    };
  },

  computed: {
    isDisabled() {
      return this.fileList.length === 0 || this.isUploading;
    },
  },

  methods: {
    handleAddFile({ file }) {
      if (this.isFileExist(file)) {
        this.toast.info(`${file.name} already exists!`, { timeout: 2000 });
        return;
      }
      const isAllowed = this.acceptedExtensions.some((ext) =>
        file.name.endsWith(ext)
      );

      if (isAllowed) {
        file['fileURL'] = URL.createObjectURL(file);
        file['uuid'] = uuid.v4();
        file['progress'] = 0;
        file['uploadedPercent'] = 0.0;
        this.fileList = [...this.fileList, file];
      }
    },

    isFileExist(file) {
      return this.fileList
        .map((e) => e.name + e.size)
        .includes(file.name + file.size);
    },

    getFileSize(sizeInBytes, showUnit = true) {
      const bytesInKB = 1024;
      const sizeInKBs = sizeInBytes / bytesInKB;
      const isSmallFile = sizeInKBs < bytesInKB;
      const unit = isSmallFile ? ' KB' : ' MB';
      const size = isSmallFile ? sizeInKBs : sizeInKBs / bytesInKB;
      return size.toFixed(2) + (showUnit ? unit : '');
    },

    handleRemove(file) {
      this.fileList = this.fileList.filter((f) => f.uuid !== file.uuid);
    },

    handlePreview(file) {
      this.videoToPreview = file;
      this.showVideoModal = true;
    },

    async uploadFiles() {
      this.$emit('upload');
      const filesResponse = [];
      this.isUploading = true;
      for (const file in this.fileList) {
        let filePayload = this.createFormData(file);
        this.fileBeingUploaded = Number(file);
        filesResponse[file] = await this.upload(filePayload);
      }
      this.isUploading = false;
      this.fileList = [];
      this.$emit('uploaded', filesResponse);
    },

    createFormData(file) {
      let formData = new FormData();
      const name = `file${file + 1}`;
      formData.append(name, this.fileList[file]);

      Object.entries(this.payload).forEach(([key, value]) => {
        formData.append(key, value);
      });

      return formData;
    },

    upload(file) {
      return new Promise(async (resolve) => {
        const [error, data] = await this.uploader(
          file,
          false,
          this.handleFileProgress
        );
        if (error) {
          console.log({ error });
        }
        resolve(data);
      });
    },

    handleFileProgress({ loaded, total }) {
      let percentCompleted = Math.floor((loaded / total) * 100);
      const temp = [...this.fileList];
      const targetFile = temp[this.fileBeingUploaded];
      targetFile.progress = percentCompleted;
      targetFile.uploadedPercent = this.getFileSize(loaded, false);
      this.fileList = temp;
    },
  },
};
</script>
<style scoped>
.disable-click {
  pointer-events: none;
}

.list-item:hover {
  background: rgb(241, 241, 241);
}

.uploading {
  background: lightgrey;
}

.invalid-file > h4 {
  color: red !important;
}
</style>
